jQuery(function($) {
  $('.main-slider ul').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true
  })
})

$(window).scroll(function() {
  let windowScroll = $(this).scrollTop()
  $('.layer1').css({
    'transform': `translate(-${windowScroll / 5}px, 0px)`
  })
  $('.layer2').css({
    'transform': `translate(-${windowScroll / 5}px, 0px)`
  })
  $('.layer3').css({
    'transform': `translate(-${windowScroll / 5}px, 0px)`
  })
})
